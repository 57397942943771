import React, { useEffect, useState, useCallback } from 'react';
import { AxiosInstance } from 'axios';
import { Icons } from '@density/dust';
import { CoreSpace } from '@densityco/lib-api-types';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { toast } from 'react-toastify';

import { Action } from './actions';
import { PasteChoice, State } from './state';
import styles from './styles.module.scss';

import Button from 'components/button';
import SelectField from 'components/select-field';
import { DateAndTimeTextField } from 'components/date-text-field';
import TextField from 'components/text-field';
import Tooltip from 'components/tooltip';
import Popup from 'components/popup';
import { LengthUnit } from 'lib/units';
import { CoreAPI, FloorplanV2Plan } from 'lib/api';
import { Analytics } from 'lib/analytics';
import { z } from 'zod';

import { FixMe } from 'types/fixme';
import KeybindingsModal from './keybindings/keybindings-modal';
import Modal from 'components/modal';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SPLITS } from 'lib/treatments';
import { useTreatment } from 'contexts/treatments';

type PastingMode =
  | PasteChoice.ROTATION
  | PasteChoice.HEIGHT
  | PasteChoice.INCREMENT
  | PasteChoice.CAPACITY
  | PasteChoice.FUNCTION
  | PasteChoice.LABELS
  | PasteChoice.TYPE
  | PasteChoice.SHAPE;

type UnitChoice = { id: LengthUnit; label: string };
type MetadataChoice = {
  id: string;
  label: string;
  editable: boolean;
  type: 'number' | 'string' | 'cost' | 'currency' | 'list' | 'date' | 'time';
  choices?: { id: string | null; label: string }[];
};
type PastingModeOption = { id: PastingMode; label: PastingMode };

type PastingChoice = {
  space: PastingModeOption[];
  sensor: PastingModeOption[];
};

interface FormFields {
  name: string;
  floor_level: number | null;
  description: string | null;
  capacity: number | null;
  target_capacity: number | null;
  cost_per_sqft: number | null;
  cost_per_sqft_currency: string | null;
  iwms_id: string | null;
  counting_mode: string | null;
  daily_reset: string | null;
}

type CoreSpace2024 = CoreSpace & {
  go_live_date_utc: string | null;
  cost_per_sqft_currency: string | null;
  cost_per_sqft: number | null;
  iwms_id: string | null;
};

const UNIT_CHOICES: Array<UnitChoice> = [
  { id: 'feet_and_inches', label: 'ft & in' },
  { id: 'inches', label: 'in' },
  { id: 'meters', label: 'm' },
  { id: 'centimeters', label: 'cm' },
  { id: 'millimeters', label: 'mm' },
];

const PASTING_CHOICES: PastingChoice = {
  space: [
    { id: PasteChoice.CAPACITY, label: PasteChoice.CAPACITY },
    { id: PasteChoice.LABELS, label: PasteChoice.LABELS },
    { id: PasteChoice.FUNCTION, label: PasteChoice.FUNCTION },
    { id: PasteChoice.INCREMENT, label: PasteChoice.INCREMENT },
    { id: PasteChoice.SHAPE, label: PasteChoice.SHAPE },
  ],
  sensor: [
    { id: PasteChoice.ROTATION, label: PasteChoice.ROTATION },
    { id: PasteChoice.HEIGHT, label: PasteChoice.HEIGHT },
    { id: PasteChoice.TYPE, label: PasteChoice.TYPE },
  ],
};

// The data we want to include in the text fields in the Modal
// from the space data
const METADATA_FIELDS: Array<MetadataChoice> = [
  { id: 'name', label: 'Name', editable: true, type: 'string' },
  { id: 'floor_level', label: 'Floor Level', editable: true, type: 'number' },
  { id: 'description', label: 'Description', editable: true, type: 'string' },
  { id: 'capacity', label: 'Capacity', editable: true, type: 'number' },
  {
    id: 'target_capacity',
    label: 'Target capacity',
    editable: true,
    type: 'number',
  },
  {
    id: 'cost_per_sqft',
    label: 'Cost per sqft',
    editable: true,
    type: 'cost',
  },
  {
    id: 'cost_per_sqft_currency',
    label: 'Cost per sqft currency',
    editable: false,
    type: 'currency',
  },
  { id: 'iwms_id', label: 'IWMS ID', editable: true, type: 'string' },
  {
    id: 'counting_mode',
    label: 'Counting mode',
    editable: true,
    type: 'list',
    choices: [
      { id: null, label: 'None' },
      { id: 'oa', label: 'Open Area' },
      { id: 'entry', label: 'Entry' },
    ],
  },
  { id: 'daily_reset', label: 'Daily reset', editable: true, type: 'time' },

  { id: 'created_at', label: 'Created at', editable: false, type: 'date' },
  { id: 'updated_at', label: 'Updated at', editable: false, type: 'date' },
];

const formSchema = z.object({
  name: z.string().min(1, { message: 'Floor must have a name' }),
  floor_level: z.number().or(z.null()),
  description: z.string().or(z.null()),
  capacity: z
    .number()
    .int()
    .min(1, { message: 'Must be a positive number empty' })
    .or(z.null()),
  target_capacity: z
    .number()
    .int()
    .min(1, { message: 'Must be a positive number or empty' })
    .or(z.null()),
  cost_per_sqft: z
    .number()
    .min(0, { message: 'Must be a positive number or empty' })
    .or(z.null()),
  iwms_id: z.string().or(z.null()),
  counting_mode: z.string().or(z.null()),
  daily_reset: z.string().or(z.null()),
});

const SettingsPanelForm = ({
  updateSpace,
  visible,
  toggleModal,
  fieldValues,
}: {
  updateSpace: (data: FormFields) => void;
  visible: boolean;
  toggleModal: () => void;
  fieldValues: FormFields;
}) => {
  // react-hook-form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      ...fieldValues,
    },
  });

  const countingMode = useWatch({ control, name: 'counting_mode' });

  const handleMetadataSubmission = (e: FormFields) => {
    try {
      updateSpace(e);
    } catch (error) {
      console.error(error);
    }
    toggleModal();
  };

  return (
    <Modal width={600} visible={visible} onBlur={toggleModal}>
      <form onSubmit={handleSubmit(handleMetadataSubmission)}>
        <div className={styles.spaceMetaHeader}>Floor Metadata</div>
        <div className={styles.spaceMetaContent}>
          {METADATA_FIELDS.map((field) => {
            const { type, label, choices, editable } = field;
            const fieldType =
              type === 'cost' ? 'number' : type === 'date' ? 'string' : type;
            const id = field.id as keyof FormFields;
            const currentVals = getValues();
            const isDailyWithOa = countingMode === 'oa' && id === 'daily_reset';
            if (type === 'currency') return null; // Skip currency fields
            return (
              <div className={styles.spaceMetaRow} key={id}>
                <div className={styles.spaceMetaRowLabel}>
                  {label}
                  {id === 'cost_per_sqft' &&
                    ` (${currentVals['cost_per_sqft_currency']})`}
                </div>
                <Controller
                  name={id as keyof FormFields}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    if (type === 'list') {
                      return (
                        <SelectField
                          size="medium"
                          data-cy={`settings-metadata-${id}`}
                          value={value || ''}
                          onChange={(choice) => {
                            setValue(id, choice.id);
                            if (choice.id === 'oa') {
                              setValue('daily_reset', null);
                            }
                          }}
                          choices={choices || []}
                        />
                      );
                    } else {
                      return (
                        <div className={styles.spaceMetaRowInput}>
                          <TextField
                            size="medium"
                            type={fieldType}
                            data-cy={`settings-metadata-${id}`}
                            disabled={!editable || isDailyWithOa}
                            placeholder="null"
                            value={
                              type === 'date'
                                ? new Date(value as string).toLocaleString()
                                : value ?? ''
                            }
                            error={errors[id]?.message ? true : false}
                            onChange={(e) => {
                              let v: string | number | null =
                                e.currentTarget.value;
                              if (fieldType === 'number') {
                                v = parseFloat(v);
                                if (isNaN(v)) v = null;
                              }
                              onChange(v);
                            }}
                          />
                          {errors[id]?.message && (
                            <p className={styles.floorMetadataError}>
                              {errors[id]?.message}
                            </p>
                          )}
                        </div>
                      );
                    }
                  }}
                />
              </div>
            );
          })}
          <div className={styles.spaceMetaRow}>
            <Button type="hollow" onClick={toggleModal}>
              Cancel
            </Button>
            <Button type="filled" buttonType="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const SettingsPanel: React.FunctionComponent<{
  state: State;
  plan: FloorplanV2Plan;
  client: AxiosInstance;
  editStatusLoading: boolean;
  onEditStatus: (status: CoreSpace['status']) => Promise<void>;
  dispatch: React.Dispatch<Action>;
}> = ({ state, plan, client, editStatusLoading, onEditStatus, dispatch }) => {
  const [floorReloadCounter, setFloorReloadCounter] = useState(0);
  const [showSpaceMetadataModal, setShowSpaceMetadataModal] = useState(false);
  const isGrafanaLinksEnabled = useTreatment(SPLITS.GRAFANA_LINKS);

  const reloadFloor = useCallback(
    () => setFloorReloadCounter((n) => n + 1),
    [setFloorReloadCounter]
  );
  const [showKeybindingsModal, setShowKeybindingsModal] = useState(false);
  const getSpaceData = useCallback(
    (abortController: AbortController) => {
      CoreAPI.getSpace(client, plan.floor.id, abortController.signal)
        .then((response) => {
          setFloor({
            status: 'complete' as const,
            data: response.data as CoreSpace2024,
          });
        })
        .catch((err) => {
          if ((err as FixMe).name === 'CanceledError') {
            return;
          }
          setFloor({ status: 'error' as const });
        });
    },
    [client, plan.floor.id]
  );
  // Load the full data for the currently focused space (of type floor) when opening the settings
  // panel.
  const [floor, setFloor] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | {
        status: 'complete';
        data: CoreSpace2024;
      }
    | {
        status: 'complete-and-updating';
        data: CoreSpace2024;
      }
    | { status: 'error' }
  >({ status: 'pending' as const });
  useEffect(() => {
    if (!state.panels.settingsOpen) {
      setFloor({ status: 'pending' as const });
      return;
    }

    const abortController = new AbortController();
    setFloor({ status: 'loading' as const });
    getSpaceData(abortController);

    return () => {
      abortController.abort();
      setFloor({ status: 'pending' as const });
    };
  }, [
    state.panels.settingsOpen,
    client,
    floorReloadCounter,
    plan.floor.id,
    getSpaceData,
  ]);

  const [goLiveDateUTCValue, setGoLiveDateUTCValue] = useState<string | null>(
    null
  );
  useEffect(() => {
    if (
      floor.status === 'complete' ||
      floor.status === 'complete-and-updating'
    ) {
      setGoLiveDateUTCValue(floor.data.go_live_date_utc);
    } else {
      setGoLiveDateUTCValue(null);
    }
  }, [floor]);

  const updateSpace = useCallback(
    (data: FormFields | CoreSpace2024) => {
      setFloor((floor) => {
        if (floor.status !== 'complete') {
          return { status: 'loading' as const };
        }

        return {
          status: 'complete-and-updating' as const,
          data: floor.data,
        };
      });
      CoreAPI.updateSpace(client, plan.floor.id, data as Partial<CoreSpace>)
        .then((response) => {
          setFloor({
            status: 'complete' as const,
            data: response.data as CoreSpace2024,
          });

          toast.success('Updated floor metadata');
        })
        .catch(() => {
          setFloor((floor) => {
            if (floor.status !== 'complete-and-updating') {
              return { status: 'error' as const };
            }
            return {
              status: 'complete' as const,
              data: floor.data,
            };
          });
          toast.error('Error updating floor metadata!');
        });
    },
    [client, plan.floor.id]
  );

  // When the go live date is changed, propagate that to the core api
  const updateFloorGoLiveDateUTC = useCallback(
    (newGoLiveDateUTC: string | null) => {
      setFloor((floor) => {
        if (floor.status !== 'complete') {
          return { status: 'loading' as const };
        }

        return {
          status: 'complete-and-updating' as const,
          data: floor.data,
        };
      });

      CoreAPI.updateSpace(client, plan.floor.id, {
        go_live_date_utc: newGoLiveDateUTC,
      })
        .then((response) => {
          setFloor({
            status: 'complete' as const,
            data: response.data as CoreSpace2024,
          });

          toast.success('Updated plan live date!');
        })
        .catch(() => {
          setFloor((floor) => {
            if (floor.status !== 'complete-and-updating') {
              return { status: 'error' as const };
            }

            return {
              status: 'complete' as const,
              data: floor.data,
            };
          });
          toast.error('Error updating plan live date!');
        });
    },
    [client, plan.floor.id]
  );

  return (
    <div className={styles.settingsPanel}>
      <KeybindingsModal
        visible={showKeybindingsModal}
        onCancel={() => setShowKeybindingsModal(false)}
        onDismiss={() => setShowKeybindingsModal(false)}
      />

      {showSpaceMetadataModal && floor.status === 'complete' && (
        <SettingsPanelForm
          updateSpace={updateSpace}
          visible={showSpaceMetadataModal}
          fieldValues={floor.data as FormFields}
          toggleModal={() => setShowSpaceMetadataModal(!showSpaceMetadataModal)}
        />
      )}
      <Popup
        open={state.panels.settingsOpen}
        onClose={() =>
          dispatch({
            type: 'panels.settings.setState',
            setOpen: false,
          })
        }
        position={{ right: 0, top: 36 }}
        popupWidth={232}
        target={
          <Tooltip
            contents="Plan Settings"
            placement="bottom"
            target={
              <Button
                onClick={() =>
                  dispatch({
                    type: 'panels.settings.setState',
                    setOpen: !state.panels.settingsOpen,
                  })
                }
                trailingIcon={<Icons.CogGearSettings size={18} />}
                size="medium"
                type="outlined"
                data-cy="settings-panel-button"
              />
            }
          />
        }
      >
        <div className={styles.settingsMenuSection}>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.RulerVertical size={16} />
              </div>
              <div className={styles.settingsMenuRowLabelText}>Units</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <SelectField
                size="small"
                value={state.displayUnit}
                onChange={(choice) => {
                  dispatch({
                    type: 'settingsPanel.setDisplayUnit',
                    displayUnit: choice.id,
                  });
                }}
                data-cy="settings-panel-unit-select"
                choices={UNIT_CHOICES}
              />
            </div>
          </div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.Paintbrush size={16} />
              </div>
              <div className={styles.settingsMenuRowLabelText}>
                Entity Paste
              </div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <SelectField
                size="small"
                value={state.entityPasteMode.mode}
                onChange={(choice) => {
                  dispatch({
                    type: 'settingsPanel.setEntityPasteMode',
                    entityPasteMode: {
                      mode: choice.id,
                      affix: 'post',
                      amount: 1,
                    },
                  });
                }}
                data-cy="settings-panel-paste-mode"
                choices={
                  state.copiedEntitySettings && state.copiedEntitySettings.type
                    ? PASTING_CHOICES[state.copiedEntitySettings.type]
                    : PASTING_CHOICES.space
                }
              />
            </div>
          </div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.Keyboard size={16} />
              </div>
              <div className={styles.settingsMenuRowLabelText}>Keybindings</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <Button
                size="small"
                onClick={() => {
                  setShowKeybindingsModal(true);
                }}
                type="hollow"
                data-cy="settings-panel-keybindings-button"
              >
                Edit Bindings
              </Button>
            </div>
          </div>
          <div className={styles.settingsMenuRowHeader}>Space</div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Status</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <SelectField
                size="small"
                value={plan.floor.status}
                width={108}
                disabled={state.locked || editStatusLoading}
                leadingIcon={
                  <div
                    style={{
                      width: dust.Space3,
                      height: dust.Space3,
                      borderRadius: '50%',
                      backgroundColor:
                        plan.floor.status === 'planning'
                          ? dust.Yellow400
                          : dust.Green400,
                      marginLeft: dust.Space2,
                    }}
                  />
                }
                onChange={(choice) =>
                  onEditStatus(choice.id).then(() => reloadFloor())
                }
                choices={[
                  { id: 'planning', label: 'Planning' },
                  { id: 'live', label: 'Live' },
                ]}
                data-cy="settings-panel-status"
              />
            </div>
          </div>
          {plan.floor.status === 'live' ? (
            <div className={styles.settingsMenuRow}>
              <div className={styles.settingsMenuRowAction}>
                {floor.status === 'complete' ||
                floor.status === 'complete-and-updating' ? (
                  <DateAndTimeTextField
                    value={goLiveDateUTCValue}
                    onChange={updateFloorGoLiveDateUTC}
                    disabled={
                      state.locked ||
                      editStatusLoading ||
                      floor.status === 'complete-and-updating'
                    }
                    size="small"
                    width="100%"
                    placeholder="eg, 10/05/22 01:00:00 pm"
                    leadingPrefix="Live at:"
                    data-cy="settings-panel-go-live-date"
                  />
                ) : (
                  <TextField
                    error={floor.status === 'error'}
                    placeholder={
                      floor.status === 'error'
                        ? 'Error loading floor!'
                        : 'Loading floor...'
                    }
                    disabled
                    size="small"
                    width="100%"
                  />
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Floor ID</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              {isGrafanaLinksEnabled ? (
                <div className={styles.settingsMenuRowSpaceId}>
                  <Tooltip
                    target={
                      <span
                        className={styles.settingsMenuCopyID}
                        onClick={() => {
                          navigator.clipboard.writeText(plan.floor.id);
                          toast.success('Copied to clipboard.');
                        }}
                      >
                        {plan.floor.id}
                      </span>
                    }
                    contents="Copy Space ID"
                  />
                  <Tooltip
                    contents={'Go to Grafana'}
                    placement="bottom"
                    target={
                      <Button
                        size="small"
                        type="link"
                        active={false}
                        onClick={() => {
                          window.open(
                            `https://grafana.data-prod.kgm3.net/d/cb082f2a-6d72-4d7f-b32c-2ab544146f35/drift-by-topology-space?var-space_id=${
                              plan.floor.id.split('_')[1]
                            }`,
                            '_blank'
                          );
                        }}
                        data-cy="external-link"
                        trailingIcon={<Icons.ChartLine size={16} />}
                      />
                    }
                  />
                </div>
              ) : (
                <div className={styles.settingsMenuRowSpaceId}>
                  {plan.floor.id}
                  <Button
                    size="nano"
                    type="cleared"
                    trailingIcon={<Icons.CopyDuplicate size={14} />}
                    onClick={() => {
                      Analytics.track('Copy Space ID', {
                        spaceType: 'floor',
                        location: 'editor',
                        spaceId: plan.floor.id,
                      });
                      navigator.clipboard.writeText(plan.floor.id);
                      toast.success('Copied to clipboard.');
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Building ID</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              {isGrafanaLinksEnabled ? (
                <div className={styles.settingsMenuRowSpaceId}>
                  <Tooltip
                    target={
                      <span
                        className={styles.settingsMenuCopyID}
                        onClick={() => {
                          navigator.clipboard.writeText(plan.floor.parent_id);
                          toast.success('Copied to clipboard.');
                        }}
                      >
                        {plan.floor.parent_id}
                      </span>
                    }
                    contents="Copy Building ID"
                  />
                  <Tooltip
                    contents={'Go to Grafana'}
                    placement="bottom"
                    target={
                      <Button
                        size="small"
                        type="link"
                        active={false}
                        onClick={() =>
                          window.open(
                            `https://grafana.data-prod.kgm3.net/d/cb082f2a-6d72-4d7f-b32c-2ab544146f35/drift-by-topology-space?var-space_id=${
                              plan.floor.parent_id.split('_')[1]
                            }`,
                            '_blank'
                          )
                        }
                        data-cy="external-link"
                        trailingIcon={<Icons.ChartLine size={16} />}
                      />
                    }
                  />
                </div>
              ) : (
                <div className={styles.settingsMenuRowSpaceId}>
                  {plan.floor.parent_id}
                  <Button
                    size="nano"
                    type="cleared"
                    trailingIcon={<Icons.CopyDuplicate size={14} />}
                    onClick={() => {
                      Analytics.track('Copy Space ID', {
                        spaceType: 'building',
                        location: 'editor',
                        spaceId: plan.floor.id,
                      });
                      navigator.clipboard.writeText(plan.floor.parent_id);
                      toast.success('Copied to clipboard.');
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>
                Floor Metadata
              </div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <Button
                type="hollow"
                size="small"
                disabled={state.locked}
                onClick={() => setShowSpaceMetadataModal(true)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default SettingsPanel;
