const NODE_ENV = process.env.NODE_ENV;

const VERSION = process.env.REACT_APP_VERSION;

// the environment of the application (staging, production, test, development)
const APP_ENV = process.env.REACT_APP_APP_ENV || 'development';

const SPLIT_KEY = process.env.REACT_APP_SPLIT_KEY || 'localhost';

const SENTRY_DSN =
  'https://1307cb473fd84c43b6d5fcfb7e7fb936@o38114.ingest.sentry.io/6036471';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export {
  VERSION,
  APP_ENV,
  SPLIT_KEY,
  NODE_ENV,
  SENTRY_DSN,
  MIXPANEL_TOKEN,
  MAPBOX_TOKEN,
};
