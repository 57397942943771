import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  CoreSpaceHierarchyNode,
  CoreOrganization,
  CoreSpaceLabel,
  CoreUser,
  CoreSpace,
} from '@densityco/lib-api-types';
import { V2CoreSpace } from './space';
import { Matrix } from 'transformation-matrix';

import { PlanDXF, PlanDXFAsset, PlanDXFExport } from 'lib/dxf';
import { ImageCoordinates } from 'lib/geometry';
import { SensorStatus } from 'lib/sensor';
import PhotoGroup, { PhotoGroupPhoto } from 'lib/photo-group';
import { SpaceCountingMode } from 'lib/space';
import { FixMe } from 'types/fixme';

// TODO: move these types to lib-api-types

export type Paginated<T> = {
  next: string | null;
  previous: string | null;
  total: number;
  results: Array<T>;
};

// This represents when a Plan is being updated and the object in question
//   does not yet have an ID from the database. This happens when you update a Plan
//   and have new objects that haven't been saved yet.
export type Unsaved<
  T extends
    | FloorplanV2Space
    | FloorplanV2SpaceWrite
    | FloorplanV2AreaOfConcern
    | FloorplanV2Sensor
    | ReferencePoint
    | FloorplanV2ReferenceRuler
    | FloorplanV2Threshold
    | FloorplanV2ThresholdWrite
    | FloorplanV2PhotoGroup
    | FloorplanV2PhotoGroupWrite
    | FloorplanV2WallSegment
> = Omit<T, 'id'>;

// NOTE: trying new ts features, don't worry about it
type CoreObjectId<P extends string> = `${P}_${number}`;

export type FloorplanSpaceId = CoreObjectId<'spc'>;
export type FloorplanAreaOfConcernId = CoreObjectId<'aoc'>;
export type FloorplanPlanSensorId = CoreObjectId<'psr'>;
export type FloorplanReferencePointId = CoreObjectId<'rep'>;
export type FloorplanReferenceHeightId = CoreObjectId<'reh'>;
export type FloorplanReferenceRulerId = CoreObjectId<'rer'>;
export type FloorplanThresholdId = CoreObjectId<'drw'>;
export type FloorplanPhotoGroupId = CoreObjectId<'ppg'>;
export type FloorplanPhotoGroupPhotoId = CoreObjectId<'pph'>;
export type FloorplanWallSegmentId = CoreObjectId<'wsg_'>;

export function isFloorplanAreaOfConcernId(
  id: string
): id is FloorplanAreaOfConcernId {
  return id.startsWith('aoc_');
}
export function isFloorplanSpaceId(id: string): id is FloorplanSpaceId {
  return id.startsWith('spc_');
}
export function isFloorplanPlanSensorId(
  id: string
): id is FloorplanPlanSensorId {
  return id.startsWith('psr_');
}
export function isFloorplanReferencePointId(
  id: string
): id is FloorplanReferencePointId {
  return id.startsWith('rep_');
}
export function isFloorplanReferenceHeightId(
  id: string
): id is FloorplanReferenceHeightId {
  return id.startsWith('reh_');
}
export function isFloorplanReferenceRulerId(
  id: string
): id is FloorplanReferenceRulerId {
  return id.startsWith('rer_');
}
export function isFloorplanThresholdId(id: string): id is FloorplanThresholdId {
  return id.startsWith('drw_');
}
export function isFloorplanPhotoGroupId(
  id: string
): id is FloorplanPhotoGroupId {
  return id.startsWith('ppg_');
}
export function isFloorplanPhotoGroupPhotoId(
  id: string
): id is FloorplanPhotoGroupPhotoId {
  return id.startsWith('pph_');
}
export function isFloorplanWallSegmentId(
  id: string
): id is FloorplanWallSegmentId {
  return id.startsWith('wsg_');
}

export type FloorplanV2CSVImportError = {
  serial_num?: string | null;
  placement_id?: string | null;
  error_type: string;
  message: string;
};

export type FloorplanV2CSVImportUpdate = {
  type: string;
  object_id: string;
  body: {
    cad_id: string;
    sensor_serial_number: string;
  };
};

export type FloorplanV2CSVImportResponse = {
  errors: Array<FloorplanV2CSVImportError>;
  updates: {
    update_type: string;
    updates: Array<FloorplanV2CSVImportUpdate>;
  };
  undos: {
    update_type: string;
    updates: Array<FloorplanV2CSVImportUpdate>;
  };
};

export type FloorplanV2Plan = {
  id: string;
  // DB Timestamps - eg. "2021-03-11T20:27:09.724000"
  created_at: string;
  updated_at: string | null;

  // Floor - Core Space with {type: 'floor'} that is 1-to-1 with this Plan
  floor_id: string; // eg. "spc_906277793787019303"
  floor: {
    id: string;
    name: string;
    parent_id: string;
    status: 'planning' | 'live';
    time_zone: string | null;
  };

  // Floorplan Base Image
  image_url: string | null;

  image_key: string | null;
  source_pdf_key: string;

  source_pdf_url?: string | null;
  source_pdf_page?: number | null;

  // Image Dimensions
  image_width_pixels: number;
  image_height_pixels: number;

  // Scale - Computed from measurement
  image_pixels_per_meter: number;

  // Measurement - Result of user interaction to measure the floorplan scale
  measurement_computed_length_meters: number;
  measurement_point_a_x_pixels: number;
  measurement_point_a_y_pixels: number;
  measurement_point_b_x_pixels: number;
  measurement_point_b_y_pixels: number;
  measurement_user_entered_length_feet: string;
  measurement_user_entered_length_inches: string;

  // Floorplan Origin - Image location treated as (0, 0) in Floorplan Coordinates
  origin_x_pixels: number;
  origin_y_pixels: number;
  origin_angle_degrees: number;

  // Keep track of last sensor index to display a new index when adding sensors
  last_oa_sensor_index?: number;
  last_entry_sensor_index?: number;

  // Floorplan CAD origin
  floorplan_cad_origin_x: number;
  floorplan_cad_origin_y: number;

  latest_dxf: Pick<PlanDXF, 'id' | 'status' | 'created_at' | 'options'> | null;
  active_dxf_id: PlanDXF['id'] | null;
  active_dxf_full_raster_url: PlanDXFAsset['object_url'] | null;

  // Ceiling Raster data
  ceiling_raster_key: string | null;
  ceiling_raster_url: string | null;
  ceiling_raster_floorplan_origin_x: number;
  ceiling_raster_floorplan_origin_y: number;
  ceiling_raster_floorplan_angle_degrees: number;
  ceiling_raster_opacity_percent: number;
  ceiling_raster_notes: string;
  ceiling_raster_min_height_limit_meters: number;
  ceiling_raster_max_height_limit_meters: number;
  point_cloud_transformation_matrix: Matrix | null;
  point_cloud_transformation_steps: string | null;
};

// Fields required to create a Plan
interface FloorplanV2PlanCreateBase {
  // ID of Core Space with {type: 'floor'} eg. "spc_906277793787019303"
  floor_id: string;
  // S3 Key of uploaded floorplan image
  image_key: string;

  // Image dimensions and scale
  image_width_pixels: number;
  image_height_pixels: number;
  image_pixels_per_meter: number;

  // Floorplan origin
  origin_x_pixels: number;
  origin_y_pixels: number;

  // Measurement data
  measurement_point_a_x_pixels: number;
  measurement_point_a_y_pixels: number;
  measurement_point_b_x_pixels: number;
  measurement_point_b_y_pixels: number;
  measurement_computed_length_meters: number;
  measurement_user_entered_length_feet: string;
  measurement_user_entered_length_inches: string;

  // Ceiling Raster data
  ceiling_raster_key: string | null;
  ceiling_raster_floorplan_origin_x: number;
  ceiling_raster_floorplan_origin_y: number;
  ceiling_raster_floorplan_angle_degrees: number;
  ceiling_raster_opacity_percent: number;
  ceiling_raster_notes: string;
  ceiling_raster_min_height_limit_meters: number;
  ceiling_raster_max_height_limit_meters: number;
  point_cloud_transformation_matrix: Matrix | null;
  point_cloud_transformation_steps: string | null;
}

// When creating a plan with a PDF, there are additional fields
interface FloorplanV2PlanCreateWithPDF extends FloorplanV2PlanCreateBase {
  // S3 Key of uploaded source PDF document
  source_pdf_key: string;
  // Page number in the PDF that was used as floorplan image
  source_pdf_page: number;
}

export type FloorplanV2TriggerRegion = {
  id: string; //doorway_id
  egress_region: Array<[number, number]>;
  ingress_region: Array<[number, number]>;
  trigger_region: Array<[number, number]>;
};

export type FloorplanV2PlanCreate =
  | FloorplanV2PlanCreateBase
  | FloorplanV2PlanCreateWithPDF;

export type FloorplanV2PlanSummary = Pick<
  FloorplanV2Plan,
  | 'id'
  | 'floor'
  | 'image_url'
  | 'source_pdf_url'
  | 'source_pdf_page'
  | 'created_at'
  | 'updated_at'
> & { active_dxf_thumbnail_url: string | null };

// This is the format that a photo group comes back from the server in.
export type FloorplanV2PhotoGroup = Omit<PhotoGroup, 'photos' | 'position'> & {
  photos: Array<{
    id: string;
    name: string;
    url: string;
    created_at: string;
    updated_at: string;
  }>;
  origin_x_pixels: number;
  origin_y_pixels: number;
};

export type FloorplanV2PhotoGroupWrite = Omit<
  FloorplanV2PhotoGroup,
  'photos' | 'operationToPerform' | 'photoIdsToDelete'
> & {
  photo_ids: Array<string>;
};

export type FloorplanV2AreaOfConcern = {
  id: string;
  name: CoreSpace['name'];
  locked: boolean;
  polygon_vertices: Array<{
    x_from_origin_meters: number;
    y_from_origin_meters: number;
  }>;
  notes: string;

  minimum_exclusive_area: number;
  sensor_height: number;
  sensor_base_angle_degrees: number;
  safety_factor_pct: number;
  coverage_intersection_heightmap_enabled: boolean;
  small_room_mode: boolean;
};

// curl 'https://core.density.io/core/v3/${organizationid}/spaces_read'
// list all external spaces
export type FloorplanV2ExternalSpace = {
  id: string;
  name: CoreSpace['name'];
  parent_name: CoreSpace['name'];
  space_type: string;
  function: CoreSpace['function'];
  labels: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
  counting_mode: SpaceCountingMode;
  created_at: string;
  time_zone: string;
  status: string;
  parent_id: string | null;
  floor_id: string | null;
  building_id: string | null;
};

export type FloorplanV2SpaceCircle = {
  id: string;
  name: CoreSpace['name'];
  capacity: CoreSpace['capacity'];
  function: CoreSpace['function'];
  labels: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
  meta: { [key: string]: any };
  locked: boolean;
  shape: 'circle';
  circle_centroid_x_meters: number;
  circle_centroid_y_meters: number;
  circle_radius_meters: number;
  polygon_verticies: null;
  counting_mode: SpaceCountingMode;
  iwms_id: string | null;
};

export type FloorplanV2SpacePolygon = {
  id: string;
  name: CoreSpace['name'];
  capacity: CoreSpace['capacity'];
  function: CoreSpace['function'];
  labels: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
  meta: { [key: string]: any };
  locked: boolean;
  shape: 'polygon';
  polygon_verticies: Array<{
    x_from_origin_meters: number;
    y_from_origin_meters: number;
  }>;
  circle_centroid_x_meters: null;
  circle_centroid_y_meters: null;
  circle_radius_meters: null;
  counting_mode: SpaceCountingMode;
  iwms_id: string | null;
};

export type FloorplanV2Space = FloorplanV2SpaceCircle | FloorplanV2SpacePolygon;

type FloorplanV2SpaceCircleWrite = Exclude<FloorplanV2SpaceCircle, 'labels'> & {
  label_ids: Array<CoreSpaceLabel['id']>;
  circle_centroid_x_meters: number;
  circle_centroid_y_meters: number;
  circle_radius_meters: number;
};
type FloorplanV2SpacePolygonWrite = Exclude<
  FloorplanV2SpacePolygon,
  'labels'
> & {
  label_ids: Array<CoreSpaceLabel['id']>;
  polygon_verticies: Array<{
    x_from_origin_meters: number;
    y_from_origin_meters: number;
  }>;
};
export type FloorplanV2SpaceWrite =
  | FloorplanV2SpaceCircleWrite
  | FloorplanV2SpacePolygonWrite;

export interface FloorplanV2Sensor {
  id: string;
  sensor_serial_number: string | null;
  sensor_type: 'oa' | 'entry';
  sensor_function: 'oa' | 'oalr' | 'oasr' | 'entry' | 'oe';
  locked: boolean;
  rotation: number; // degrees
  centroid_from_origin_x_meters: number;
  centroid_from_origin_y_meters: number;
  height_meters: number;
  last_heartbeat?: string | null;
  status?: SensorStatus;
  diagnostic_info?: {
    ipv4?: string | null;
    ipv6?: string | null;
    os?: string | null;
    mac?: string | null;
    low_power_mode?: boolean | null;
  };
  notes?: string;
  plan_sensor_index?: number | null;
  cad_id: string;
  bounding_box_filter: 'none' | 'cloud' | 'device';
  clipped_fov?: Array<{
    x_meters: number;
    y_meters: number;
  }>;
  doorway_clipped_fov?: Array<{
    x_meters: number;
    y_meters: number;
  }>;
  noise_polygons?: Array<{
    min_vertical: number | null;
    max_vertical: number | null;
    region: Array<number[]>;
  }>;
}

interface ReferencePoint {
  id: string;
  position_x_meters: number;
  position_y_meters: number;
  enabled: boolean;
}

export interface FloorplanV2ReferenceHeight {
  id: string;
  position_x_meters: number;
  position_y_meters: number;
  enabled: boolean;
}

export interface FloorplanV2ReferenceRuler {
  id: string;
  position_a_x_meters: number;
  position_a_y_meters: number;
  position_b_x_meters: number;
  position_b_y_meters: number;
  position_label_x_meters: number;
  position_label_y_meters: number;
  enabled: boolean;
}

export interface FloorplanV2ThresholdWrite {
  id: string;
  name: string;
  position_a_x_meters: number;
  position_a_y_meters: number;
  position_b_x_meters: number;
  position_b_y_meters: number;
  spaces: {
    space_id: string;
    sensor_placement: number;
    link_id: string | null;
  }[];
  plan_sensor_ids: string[];
  locked: boolean;
  notes: string;
  swings_into_fov: boolean;
}

export interface FloorplanV2Threshold {
  id: string;
  name: string;
  position_a_x_meters: number;
  position_a_y_meters: number;
  position_b_x_meters: number;
  position_b_y_meters: number;
  spaces: {
    space_id: string;
    sensor_placement: number;
    link_id: string | null;
    space_name: string;
    type: string;
    plan_id?: string;
  }[];
  plan_sensor_ids: string[];
  locked: boolean;
  notes: string;
  trigger_region: FloorplanV2TriggerRegion;
  swings_into_fov: boolean;
}

export interface FloorplanV2WallSegment {
  id: string;
  position_a_x_meters: number;
  position_a_y_meters: number;
  position_b_x_meters: number;
  position_b_y_meters: number;
  type: 'wall' | 'doorway';
}

export type FloorplanPlanImageSignedURLCreate = {
  // ID of Core Space with {type: 'floor'} eg. "spc_906277793787019303"
  floor_id: string;
  // File extension, eg. "png"
  ext: string;
  // MIME type of image
  content_type: string;
};

type FloorplanV2PlanImageSignedURLResponse = {
  // Signed S3 URL to upload the image to
  signed_url: string;
  // This signed url can be used to GET the data uploaded via PUT to `signed_url`
  get_signed_url: string;
  key: string;
  content_type: string;
};

export type FloorplanV2ProjectLoginCode = {
  building: {
    id: CoreSpace['id'];
    name: CoreSpace['name'];
    organization: {
      id: CoreOrganization['id'];
      name: CoreOrganization['name'];
    };
  };
  expires_at: string;
  login_code: string;
};

export type HelixAPISignedUrls = {
  keyname: string;
  expires: number;
  urls: { [key: string]: string };
  previewLink: string;
};

export type HelixFolioSummary = {
  id: string;
  name: string;
  slug: string;
  ownerName: string;
  address: string;
  lastPublished: number;
  scanDate: number;
  deliveryDate: number;
  deliveryStatus: 'UNSCANNED' | 'SCHEDULED' | 'IN_PROGRESS' | 'DELIVERED';
  createdAt: number;
  useSpaceDates: boolean;
  publication: {
    status: 'PUBLISHED';
    lastPublishedDate: number;
  };
  displayUnits: 'IMPERIAL';
  demo: boolean;
  link: string;
  linkDescription: string;
};

export type HelixSpace = {
  id: string;
  name: string;
  active: boolean;
  deliveryStatus: 'UNSCANNED' | 'SCHEDULED' | 'IN_PROGRESS' | 'DELIVERED';
  densityPlanId: string;
  scanDate: number;
  deliveryDate: number;
  // "mapAsset": "813c5b1a-5900-49d6-a4a8-7c82cc99862a/spaces/ff5e906f-0ba3-4bb3-93d7-8bb10a35397b/maps/bldg21.jpeg",
  mapAsset?: string;
  floorPlan?: {
    id: string;
    status: 'COMPLETE' | FixMe;
    active: boolean;
    spaceId: string;
    createdAt: number;
    updatedAt: number;
    folioId: string;
    attachmentData: {
      id: string;
      cardTitle: string;
      previewable: boolean;
      downloadable: boolean;
      downloadLink: string;
      status: 'COMPLETE' | FixMe;
      spaceIds: Array<HelixSpace['id']>;
      source: 'FLOORPLAN_UPLOAD_DIRECT' | FixMe;
      type: 'IMAGE' | FixMe;
      createdAt: number;
      requesterId: string;
      size: number;
      updatedAt: number;
      originalFileName: string;
    };
    annotations: [];
  };
  scenes: {
    [sceneId: string]: {
      mapX: number;
      mapY: number;
    };
  };
};

export type HelixScene = {
  id: string;
  faceSize: number;
  initialViewParameters: {
    yaw: number;
    pitch: number;
    fov: number;
    roll: number;
  };
  levels: Array<{
    size: number;
    fallbackOnly: boolean;
    tileSize: number;
  }>;
  linkHotspots: [];
  annotations: [];
  name: string;
  angle: number;
  state: 'SUCCESS' | FixMe;
  sensitive: boolean;
  cleared: boolean;
  redacted: boolean;
  sceneType: 'SCENE_360' | FixMe;
  srcImgUrl: string;
  inventoryFound: boolean;
  inventoryTile: 'left' | '' | FixMe;
  inventoryClass: 'lamp' | '' | FixMe;
  slamPath: string;
  slamX: string;
  slamY: string;
};

export type HelixAttachment = {
  id: string;
  cardTitle: string;
  previewable: boolean;
  downloadable: boolean;
  downloadLink: string;
  status: 'COMPLETE' | FixMe;
  spaceIds: Array<HelixSpace['id']>;
  source: 'CEILING_HEIGHT_RASTER' | 'SCENE' | FixMe;
  type: 'POINT_CLOUD' | 'POINT_CLOUD_RASTER' | 'REVIT' | 'IMAGE_360' | FixMe;
  createdAt: number;
  requesterId: string;
  size: number;
  updatedAt: number;
  originalFileName: string;
  assetViews: Array<FixMe>;
};

export type HelixFolio = {
  id: string;
  config: {
    name: string;
    address: string;
    initialSpace: string;
    thumbnail: string;
    // Thumbnail: https://api.helix.re/v1/folios/813c5b1a-5900-49d6-a4a8-7c82cc99862a/thumbnails/1662497520390-bldg21.png?access_token=HELIX TOKEN
    ownerId: string;
    ownerName: string;
    scanDate: number;
    deliveryDate: number;
    latitude: number;
    longitude: number;
    displayUnits: 'IMPERIAL' | FixMe;
    deliveryStatus: 'UNSCANNED' | 'SCHEDULED' | 'IN_PROGRESS' | 'DELIVERED';
    useSpaceDates: boolean;
    useSpaceArea: boolean;
  };
  slug: string;
  // spaces: Array<Pick<HelixSpace, 'id' | 'name' | 'scanDate' | 'scenes' | 'densityPlanId' | 'deliveryStatus' | 'deliveryDate' | 'active'>>;
  spaces: Array<HelixSpace>;
  scenes: Array<HelixScene>;
  models: Array<FixMe>;
  publication: {
    status: 'PUBLISHED' | FixMe;
    lastPublishedDate: number;
  };
  isPublic: boolean;
  link: string;
  linkDescription: string;
};

export type HelixPointCloud = {
  id: string;
  name: string;
  comment: '';
  state: 'COMPLETE' | 'FAILURE' | FixMe;
  type: 'COMBINED';
  minPointSpacing: number;
  transformationMatrix: string;
  attachment: HelixAttachment;
  rawPointClouds: Array<FixMe>;
  spaceIds: Array<HelixSpace['id']>;
  parents: Array<{
    id: string;
    name: string;
  }>;
  thumbnails?: {
    small: string;
    medium: string;
    large: string;
    fullres: string;
    original: string;
  };
  pointCount: number;
  categorizePoints: boolean;
  spaceClassMap: {
    [spaceClassId: string]: Array<number>;
  };
  categories: Array<{
    name: 'ARCHITECTURE' | FixMe;
    type: 'CATEGORY' | FixMe;
    color: string;
    children: Array<{
      name: 'FLOOR' | FixMe;
      type: 'CLASS' | FixMe;
      code: number;
      color: string;
      pointRatio?: number;
    }>;
  }>;
};

export type HelixPointCloudDerivative = {
  attachment: HelixAttachment;
  createdAt: number;
  documentId: string;
  documentPath: string;
  id: string;
  name: string;
  type: 'CEILING' | FixMe;
};

export function getUser(client: AxiosInstance) {
  return client.get<CoreUser>('v2/users/me');
}

export type SpaceMetadataV3CSVImportSuccess = {
  space_id: string;
  name: string;
};

export type SpaceMetadataV3CSVImportFailure = {
  space_id: string;
  error: string;
};

export type SpaceMetadataV3CSVImportLabelUpdate = {
  space_id: string;
  labelName: string;
};

export type SpaceMetadataV3CSVImportResponse = {
  space_successes: number;
  space_failures: number;
  space_updates: Array<SpaceMetadataV3CSVImportSuccess>;
  failed_space_updates: Array<SpaceMetadataV3CSVImportFailure>;
  labels_added: Array<SpaceMetadataV3CSVImportLabelUpdate>;
  labels_deleted: Array<SpaceMetadataV3CSVImportLabelUpdate>;
  label_failures: Array<SpaceMetadataV3CSVImportFailure>;
};

type CoreAPISensorDiagnostics = {
  network_addresses: Array<{
    if: string;
    family: 'ipv4' | 'ipv6';
    address: string;
    mac: string;
  }>;
  last_heartbeat: string;
  current_status: SensorStatus;
  os: { VERSION_ID: string };
  low_power_mode: boolean;
};

type PhotoGroupsImageUploadBody = {
  photo_id: string;
  upload_url: string;
};

// used for loading bars
export const ENTITY_PAGE_SIZE = 200;

export const CoreAPI = {
  listOrganizations(client: AxiosInstance) {
    return client.get<Array<CoreOrganization>>('v2/organizations', {
      headers: {
        'X-Impersonate-User': '',
      },
    });
  },
  listUsersInOrganization(
    client: AxiosInstance,
    organizationId: CoreOrganization['id']
  ) {
    return client.get<Array<CoreUser>>(
      `v2/users?organization_id=${organizationId}`,
      {
        headers: {
          'X-Impersonate-User': '',
        },
      }
    );
  },
  createOrganizationWithServiceUser(
    client: AxiosInstance,
    newOrganizationName: CoreOrganization['name']
  ) {
    return client.post<CoreOrganization & { service_user: CoreUser }>(
      `v2/organizations/create_with_service_user`,
      {
        name: newOrganizationName,
        description: newOrganizationName,
      },
      {
        headers: {
          'X-Impersonate-User': '',
        },
      }
    );
  },
  spacesHierarchy(client: AxiosInstance, signal?: AbortSignal) {
    return client.get<Array<CoreSpaceHierarchyNode>>('v2/spaces/hierarchy', {
      signal,
    });
  },
  spacesHierarchyWithinSpace(
    client: AxiosInstance,
    parentSpaceId: CoreSpace['id'],
    token?: string
  ) {
    return client.get<CoreSpaceHierarchyNode>(
      `v2/spaces/${parentSpaceId}/hierarchy`,
      token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
    );
  },
  spacesChildren(
    client: AxiosInstance,
    parentId: CoreSpace['id'],
    signal?: AbortSignal
  ) {
    return client.get<
      Pick<
        CoreSpace,
        | 'id'
        | 'name'
        | 'space_type'
        | 'daily_reset'
        | 'capacity'
        | 'address'
        | 'function'
        | 'time_zone'
      > & {
        children: Array<
          Pick<
            CoreSpace,
            | 'id'
            | 'name'
            | 'space_type'
            | 'daily_reset'
            | 'capacity'
            | 'address'
            | 'function'
            | 'time_zone'
          >
        >;
      }
    >(`v2/spaces/${parentId}/children`, { signal });
  },
  getSpace(
    client: AxiosInstance,
    spaceId: V2CoreSpace['id'],
    signal?: AbortSignal
  ) {
    return client.get<
      V2CoreSpace & { go_live_date_utc: string | null; iwms_id: string | null }
    >(`v2/spaces/${spaceId}`, { signal });
  },
  createSpace(client: AxiosInstance, data: Partial<CoreSpace>) {
    return client.post<CoreSpace>(`v2/spaces`, data);
  },
  updateSpace(
    client: AxiosInstance,
    spaceId: CoreSpace['id'],
    data: Partial<CoreSpace & { go_live_date_utc: string | null }>
  ) {
    return client.put<CoreSpace & { go_live_date_utc: string | null }>(
      `v2/spaces/${spaceId}`,
      data
    );
  },
  deleteSpace(
    client: AxiosInstance,
    spaceId: CoreSpace['id'],
    spaceName: CoreSpace['name']
  ) {
    return client.delete(`v2/spaces/${spaceId}`, { data: { name: spaceName } });
  },
  getSensorDiagnostics(
    client: AxiosInstance,
    sensorSerialNumber: string
  ): Promise<AxiosResponse<CoreAPISensorDiagnostics>> {
    return client.get(`v2/sensors/${sensorSerialNumber}/diagnostics`);
  },
  locateSensor(client: AxiosInstance, sensorSerialNumber: string) {
    return client.post(
      `v2/sensors/${sensorSerialNumber}/locate?immediate=true`
    );
  },
  createProjectLoginCode(
    client: AxiosInstance,
    buildingId: CoreSpace['id'],
    expiresAt: string
  ) {
    return client.post<FloorplanV2ProjectLoginCode>(
      `v2/project-login/${buildingId}/code/`,
      { expires_at: expiresAt }
    );
  },
  getProjectLoginCode(client: AxiosInstance, buildingId: CoreSpace['id']) {
    return client.get<FloorplanV2ProjectLoginCode>(
      `v2/project-login/${buildingId}/code/`
    );
  },
  updateProjectLoginCode(
    client: AxiosInstance,
    buildingId: CoreSpace['id'],
    expiresAt: string
  ) {
    return client.put<FloorplanV2ProjectLoginCode>(
      `v2/project-login/${buildingId}/code/`,
      { expires_at: expiresAt }
    );
  },
  loginWithProjectLoginCode(client: AxiosInstance, code: string) {
    return client.post<
      Omit<FloorplanV2ProjectLoginCode, 'login_code'> & { token: string }
    >(`v2/project-login/`, { code });
  },
  getSpaceFunctions(client: AxiosInstance, signal?: AbortSignal) {
    return client.get<
      Array<{
        function: string;
        function_display_name: string;
        program: string;
        program_display_name: string;
      }>
    >('/v2/spaces/functions', { signal });
  },
  getSpaceLabels(
    client: AxiosInstance,
    page: number = 1,
    signal?: AbortSignal
  ) {
    return client.get<Paginated<Pick<CoreSpaceLabel, 'id' | 'name'>>>(
      `/core/v2/labels?page_size=200&page=${page}`,
      { signal }
    );
  },
  createSpaceLabel(client: AxiosInstance, labelName: CoreSpaceLabel['name']) {
    return client.post<CoreSpaceLabel>('/v2/labels', { name: labelName });
  },
  addLabelToSpace(
    client: AxiosInstance,
    spaceId: CoreSpace['id'],
    labelId: CoreSpaceLabel['id']
  ) {
    return client.post<Array<CoreSpaceLabel>>(`app/spaces/${spaceId}/labels`, [
      { id: labelId },
    ]);
  },
  removeLabelFromSpace(
    client: AxiosInstance,
    spaceId: CoreSpace['id'],
    labelId: CoreSpaceLabel['id']
  ) {
    return client.delete(`app/spaces/${spaceId}/labels?label_ids=${labelId}`);
  },
  bulkUpdateSpaceMetadata(
    client: AxiosInstance,
    orgId: string,
    metadataFile: File
  ) {
    const formData = new FormData();
    formData.append('metadata', metadataFile);
    return client.post<SpaceMetadataV3CSVImportResponse>(
      `core/v3/spaces/meta/csv`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  },
};

// NOTE: This token is not valid - it has a very short expiry time and I have been waiting to push
// up my changes until after it expires. This will not be here very soon.
export const HELIX_TOKEN =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6IjVhNTA5ZjAxOWY3MGQ3NzlkODBmMTUyZDFhNWQzMzgxMWFiN2NlZjciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiUnlhbiBHYXVzIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BT2gxNEdqZ0UtWEdTbF92TVZ2Ym5KZ0ZqWUlPSzFYRWdJTHM3WFZjTFZJUD1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9oZWxpeC1wcm9kLTIwMDYxOCIsImF1ZCI6ImhlbGl4LXByb2QtMjAwNjE4IiwiYXV0aF90aW1lIjoxNjY1MTg3NTgxLCJ1c2VyX2lkIjoiYkdJRkplOUJTZVVPUXJiTEZnc2M1Q0UzdFR2MiIsInN1YiI6ImJHSUZKZTlCU2VVT1FyYkxGZ3NjNUNFM3RUdjIiLCJpYXQiOjE2NzU5Nzk0MzUsImV4cCI6MTY3NTk4MzAzNSwiZW1haWwiOiJyeWFuQGRlbnNpdHkuaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwMTgwMzgyMjc4MTQyMTA4MDg5OSJdLCJlbWFpbCI6WyJyeWFuQGRlbnNpdHkuaW8iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.RqBuN-dQxFkU9nUn8O_CrwPYDrJUF9yNJUo9ztU12o3drUaeMjvFAP8I0nQyCGOLx6w1vcusbTo1O06ihaRUWvwFvRkY_C1cog9I5B-t0zYmMI6lQk9hThAIcFj432NDvYeW6piILICYfh82Rs5Yh066MQMDLuKalchicMlkchsdJr40iOow18iEyjfx1vLPXZNmczmuf_16UjuDCEKlv7c5ur47Nf5iZx6rA1vrDxmUGMxOe4KglvaA8gJYqClHhU1g1IdXprPb6mEwFhs8-Q8zqKA559dLE7ATNjpCQuZMftARPzi7knimMKPSy1H-96jXoWJxCVEZijzanloMjQ';

export const HelixAPI = {
  getFolios(helixToken: string, signal?: AbortSignal) {
    return axios.get<Array<HelixFolioSummary>>(
      `https://api.helix.re/v1/folios`,
      {
        headers: {
          Authorization: `Bearer ${helixToken}`,
        },
        signal,
      }
    );
  },
  getFolio(
    helixToken: string,
    folioId: HelixFolio['id'],
    signal?: AbortSignal
  ) {
    return axios.get<HelixFolio>(`https://api.helix.re/v1/folios/${folioId}`, {
      headers: {
        Authorization: `Bearer ${helixToken}`,
      },
      signal,
    });
  },
  getFolioPointClouds(
    helixToken: string,
    folioId: HelixFolio['id'],
    signal?: AbortSignal
  ) {
    return axios.get<Array<HelixPointCloud>>(
      `https://api.helix.re/v1/folios/${folioId}/pointclouds`,
      {
        headers: {
          Authorization: `Bearer ${helixToken}`,
        },
        signal,
      }
    );
  },
  getFolioPointCloudDerivatives(
    helixToken: string,
    folioId: HelixFolio['id'],
    pointCloudId: HelixPointCloud['id'],
    signal?: AbortSignal
  ) {
    return axios.get<Array<HelixPointCloudDerivative>>(
      `https://api.helix.re/v1/folios/${folioId}/pointclouds/${pointCloudId}/derivatives`,
      {
        headers: {
          Authorization: `Bearer ${helixToken}`,
        },
        signal,
      }
    );
  },
  getPointCloudSignatures(
    helixToken: string,
    folioId: HelixFolio['id'],
    pointCloudId: string
  ) {
    return axios.get<HelixAPISignedUrls>(
      `https://api.helix.re/v1/folios/${folioId}/pointclouds/${pointCloudId}/signatures`,
      {
        headers: {
          Authorization: `Bearer ${helixToken}`,
        },
      }
    );
  },
};

export const FloorplanAPI = {
  listFloorplans(client: AxiosInstance, token?: string, signal?: AbortSignal) {
    return client.get<Paginated<FloorplanV2PlanSummary>>('v2/floorplans', {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      signal,
    });
  },
  getFloorplan(client: AxiosInstance, id: FloorplanV2Plan['id']) {
    return client.get<FloorplanV2Plan>(`v2/floorplans/${id}`);
  },
  createFloorplan(client: AxiosInstance, data: Partial<FloorplanV2PlanCreate>) {
    return client.post<FloorplanV2Plan>('v2/floorplans', data);
  },
  uploadCSV(client: AxiosInstance, planId: FloorplanV2Plan['id'], file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return client.post<FloorplanV2CSVImportResponse>(
      `v2/floorplans/${planId}/serialnumbers/csv`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  clipFloorplanFOV(
    client: AxiosInstance,
    orgId: CoreOrganization['id'],
    planId: string
  ) {
    return client.put(`v2/${orgId}/floorplans/${planId}/calculate-fov`);
  },

  updateFloorplanScale(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    rawMeasurement: Pick<
      FloorplanV2Plan,
      | 'image_pixels_per_meter'
      | 'measurement_point_a_x_pixels'
      | 'measurement_point_a_y_pixels'
      | 'measurement_point_b_x_pixels'
      | 'measurement_point_b_y_pixels'
      | 'measurement_computed_length_meters'
      | 'measurement_user_entered_length_feet'
      | 'measurement_user_entered_length_inches'
    >
  ) {
    return client.put<FloorplanV2Plan>(
      `v2/floorplans/${planId}/scale`,
      rawMeasurement
    );
  },
  updateFloorplanImage(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    imageKey: string | null,
    imageWidthInPixels: number,
    imageHeightInPixels: number,
    rawMeasurement?: Pick<
      FloorplanV2Plan,
      | 'image_pixels_per_meter'
      | 'measurement_point_a_x_pixels'
      | 'measurement_point_a_y_pixels'
      | 'measurement_point_b_x_pixels'
      | 'measurement_point_b_y_pixels'
      | 'measurement_computed_length_meters'
      | 'measurement_user_entered_length_feet'
      | 'measurement_user_entered_length_inches'
    >,
    origin?: ImageCoordinates,
    floorplanRotation?: number
  ) {
    return client.put<FloorplanV2Plan>(`v2/floorplans/${planId}/image`, {
      image_key: imageKey,
      image_width_pixels: imageWidthInPixels,
      image_height_pixels: imageHeightInPixels,
      origin_x_pixels: origin ? origin.x : undefined,
      origin_y_pixels: origin ? origin.y : undefined,
      origin_angle_degrees: floorplanRotation,
      ...(rawMeasurement || {}),
    });
  },
  imageUpload(client: AxiosInstance, data: FloorplanPlanImageSignedURLCreate) {
    return client.post<FloorplanV2PlanImageSignedURLResponse>(
      'v2/floorplans/image-upload',
      data
    );
  },
  createAndProcessDXF(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    uploadedDXFKey: string
  ): Promise<AxiosResponse<PlanDXF>> {
    return client.post(`v2/floorplans/${planId}/dxfs/process`, {
      uploaded_dxf_key: uploadedDXFKey,
    });
  },
  getDXF(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    dxfId: PlanDXF['id']
  ): Promise<AxiosResponse<PlanDXF>> {
    return client.get(`v2/floorplans/${planId}/dxfs/${dxfId}`);
  },
  updateDXF(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    dxfId: PlanDXF['id'],
    dxf: Partial<PlanDXF>
  ): Promise<AxiosResponse> {
    return client.patch(`v2/floorplans/${planId}/dxfs/${dxfId}`, dxf);
  },
  updateAndReparsePlanDXF(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    dxfId: PlanDXF['id'],
    options: PlanDXF['options']
  ): Promise<AxiosResponse> {
    return client.put(`v2/floorplans/${planId}/dxfs/${dxfId}/reprocess`, {
      options,
    });
  },
  updatePlanActiveDXFId(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    activeDXFId: PlanDXF['id'] | null
  ): Promise<AxiosResponse> {
    return client.patch(`v2/floorplans/${planId}`, {
      active_dxf_id: activeDXFId,
    });
  },
  createExport(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    exportParams: Partial<PlanDXFExport> = {}
  ): Promise<AxiosResponse<PlanDXFExport>> {
    return client.post(`v2/floorplans/${planId}/exports`, exportParams);
  },

  updateCeilingRaster(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    key: string | null,
    positionX?: number,
    positionY?: number,
    angleDegrees?: number,
    notes?: string,
    opacityPercent?: number,
    minHeightLimitMeters?: number,
    maxHeightLimitMeters?: number,
    pointCloudTransformationMatrix?: Matrix,
    pointCloudTransformationSteps?: string
  ): Promise<AxiosResponse> {
    return client.put(`v2/floorplans/${planId}/ceiling-raster`, {
      ceiling_raster_key: key,
      ceiling_raster_floorplan_origin_x: positionX,
      ceiling_raster_floorplan_origin_y: positionY,
      ceiling_raster_floorplan_angle_degrees: angleDegrees,
      ceiling_raster_notes: notes,
      ceiling_raster_opacity_percent: opacityPercent,
      ceiling_raster_min_height_limit_meters: minHeightLimitMeters,
      ceiling_raster_max_height_limit_meters: maxHeightLimitMeters,
      point_cloud_transformation_matrix: pointCloudTransformationMatrix,
      point_cloud_transformation_steps: pointCloudTransformationSteps,
    });
  },

  listAllOrganizationSpaces(client: AxiosInstance) {
    return client.get<Paginated<FloorplanV2ExternalSpace>>(
      `core/v3/spaces_read?page_size=1000`
    );
  },

  listSpaces(client: AxiosInstance, id: FloorplanV2Plan['id'], page = 1) {
    return client.get<Paginated<FloorplanV2Space>>(
      `v2/floorplans/${id}/spaces?page_size=${ENTITY_PAGE_SIZE}&page=${page}`
    );
  },

  createSpace(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2SpaceWrite>
  ): Promise<AxiosResponse<FloorplanV2Space>> {
    return client.post(`v2/floorplans/${planId}/spaces`, {
      ...data,
      id: undefined,
    });
  },
  updateSpace(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    spaceId: FloorplanV2Space['id'],
    data: Partial<FloorplanV2Space>
  ): Promise<AxiosResponse<FloorplanV2Space>> {
    return client.patch(`v2/floorplans/${planId}/spaces/${spaceId}`, data);
  },
  deleteSpace(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    spaceId: FloorplanV2Space['id']
  ): Promise<AxiosResponse> {
    return client.delete(`v2/floorplans/${planId}/spaces/${spaceId}`);
  },

  listAreasOfConcern(
    client: AxiosInstance,
    id: FloorplanV2Plan['id'],
    page = 1
  ) {
    return client.get<Paginated<FloorplanV2AreaOfConcern>>(
      `v2/floorplans/${id}/areas-of-coverage?page_size=${ENTITY_PAGE_SIZE}&page=${page}`
    );
  },
  createAreaOfConcern(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2AreaOfConcern>
  ): Promise<AxiosResponse<FloorplanV2AreaOfConcern>> {
    return client.post(`v2/floorplans/${planId}/areas-of-coverage`, {
      ...data,
      id: undefined,
    });
  },
  updateAreaOfConcern(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    areaOfConcernId: FloorplanV2AreaOfConcern['id'],
    data: Partial<FloorplanV2AreaOfConcern>
  ): Promise<AxiosResponse<FloorplanV2AreaOfConcern>> {
    return client.patch(
      `v2/floorplans/${planId}/areas-of-coverage/${areaOfConcernId}`,
      data
    );
  },
  deleteAreaOfConcern(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    areaOfConcernId: FloorplanV2AreaOfConcern['id']
  ): Promise<AxiosResponse> {
    return client.delete(
      `v2/floorplans/${planId}/areas-of-coverage/${areaOfConcernId}`
    );
  },
  listSensors(client: AxiosInstance, id: FloorplanV2Plan['id'], page = 1) {
    return client.get<Paginated<FloorplanV2Sensor>>(
      `v2/floorplans/${id}/sensors?page_size=${ENTITY_PAGE_SIZE}&page=${page}`
    );
  },
  listOrgSensors(client: AxiosInstance, page = 1) {
    return client.get<Paginated<FloorplanV2Sensor>>(
      `v2/sensors?page_size=200&page=${page}`
    );
  },
  createSensor(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2Sensor>
  ): Promise<AxiosResponse<FloorplanV2Sensor>> {
    return client.post(`v2/floorplans/${planId}/sensors`, {
      ...data,
      id: undefined,
    });
  },
  updateSensor(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    planSensorId: FloorplanV2Sensor['id'],
    data: Partial<FloorplanV2Sensor>
  ): Promise<AxiosResponse<FloorplanV2Sensor>> {
    return client.patch(
      `v2/floorplans/${planId}/sensors/${planSensorId}`,
      data
    );
  },
  deleteSensor(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    planSensorId: FloorplanV2Sensor['id']
  ): Promise<AxiosResponse> {
    return client.delete(`v2/floorplans/${planId}/sensors/${planSensorId}`);
  },
  listThresholds(client: AxiosInstance, id: FloorplanV2Plan['id'], page = 1) {
    return client.get<Paginated<FloorplanV2Threshold>>(
      `v2/floorplans/${id}/doorways?page_size=100&page=${page}`
    );
  },
  createThreshold(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2ThresholdWrite>
  ): Promise<AxiosResponse<FloorplanV2ThresholdWrite>> {
    return client.post(`v2/floorplans/${planId}/doorways`, {
      ...data,
      id: undefined,
    });
  },
  updateThreshold(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    thresholdId: FloorplanV2Threshold['id'],
    data: Partial<FloorplanV2Threshold>
  ): Promise<AxiosResponse<FloorplanV2Threshold>> {
    return client.patch(
      `v2/floorplans/${planId}/doorways/${thresholdId}`,
      data
    );
  },
  deleteThreshold(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    thresholdId: FloorplanV2Threshold['id']
  ): Promise<AxiosResponse> {
    return client.delete(`v2/floorplans/${planId}/doorways/${thresholdId}`);
  },
  clipFieldOfView(
    // clip FOV to nearby walls
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    sensorId: FloorplanV2Sensor['id']
  ) {
    return client.put(
      `v2/floorplans/${planId}/sensors/${sensorId}/calculate-fov`,
      {}
    );
  },
  unclipFieldOfView(
    // restore the original FOV by setting clipped FOV to []
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    sensorId: FloorplanV2Sensor['id']
  ) {
    return client.delete(
      `v2/floorplans/${planId}/sensors/${sensorId}/calculate-fov`
    );
  },
  generateNoiseFilter(
    // generate noise polygons for static noe
    client: AxiosInstance,
    sensorSerialNumber: FloorplanV2Sensor['sensor_serial_number']
  ) {
    return client.post(`core/v3/sensors/generate_noise_filters`, {
      serial_numbers: [sensorSerialNumber],
    });
  },
  calculateTriggerRegion(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    thresholdId: FloorplanV2Threshold['id']
  ) {
    return client.post(`v2/floorplans/${planId}/thresholds/calculate`, {
      doorway_id: thresholdId,
      push_to_doorway: true,
    });
  },
  listReferenceRulers(
    client: AxiosInstance,
    id: FloorplanV2Plan['id'],
    page = 1
  ) {
    return client.get<Paginated<FloorplanV2ReferenceRuler>>(
      `v2/floorplans/${id}/reference-rulers?page_size=100&page=${page}`
    );
  },
  createReferenceRuler(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2ReferenceRuler>
  ): Promise<AxiosResponse<FloorplanV2ReferenceRuler>> {
    return client.post(`v2/floorplans/${planId}/reference-rulers`, {
      ...data,
      id: undefined,
    });
  },
  updateReferenceRuler(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    referenceRulerId: FloorplanV2ReferenceRuler['id'],
    data: Partial<FloorplanV2ReferenceRuler>
  ): Promise<AxiosResponse<FloorplanV2ReferenceRuler>> {
    return client.patch(
      `v2/floorplans/${planId}/reference-rulers/${referenceRulerId}`,
      data
    );
  },
  deleteReferenceRuler(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    referenceRulerId: FloorplanV2ReferenceRuler['id']
  ): Promise<AxiosResponse> {
    return client.delete(
      `v2/floorplans/${planId}/reference-rulers/${referenceRulerId}`
    );
  },
  listReferenceHeights(
    client: AxiosInstance,
    id: FloorplanV2Plan['id'],
    page = 1
  ) {
    return client.get<Paginated<FloorplanV2ReferenceHeight>>(
      `v2/floorplans/${id}/reference-heights?page_size=100&page=${page}`
    );
  },
  createReferenceHeight(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2ReferenceHeight>
  ): Promise<AxiosResponse<FloorplanV2ReferenceHeight>> {
    return client.post(`v2/floorplans/${planId}/reference-heights`, {
      ...data,
      id: undefined,
    });
  },
  updateReferenceHeight(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    referenceHeightId: FloorplanV2ReferenceHeight['id'],
    data: Partial<FloorplanV2ReferenceHeight>
  ): Promise<AxiosResponse<FloorplanV2ReferenceHeight>> {
    return client.patch(
      `v2/floorplans/${planId}/reference-heights/${referenceHeightId}`,
      data
    );
  },
  deleteReferenceHeight(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    referenceHeightId: FloorplanV2ReferenceHeight['id']
  ): Promise<AxiosResponse> {
    return client.delete(
      `v2/floorplans/${planId}/reference-heights/${referenceHeightId}`
    );
  },
  listPhotoGroups(client: AxiosInstance, id: FloorplanV2Plan['id'], page = 1) {
    return client.get<Paginated<FloorplanV2PhotoGroup>>(
      `v2/floorplans/${id}/photo-groups?page_size=100&page=${page}`
    );
  },
  createPhotoGroup(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    data: Unsaved<FloorplanV2PhotoGroupWrite>
  ): Promise<AxiosResponse<FloorplanV2PhotoGroup>> {
    return client.post(`v2/floorplans/${planId}/photo-groups`, {
      ...data,
      id: undefined,
    });
  },

  getPhotoGroupUploadImageUrl(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    fileName: string,
    contentType: string
  ): Promise<AxiosResponse<PhotoGroupsImageUploadBody>> {
    return client.post(`v2/floorplans/${planId}/photo-groups/upload`, {
      file_name: fileName,
      content_type: contentType,
    });
  },

  updatePhotoGroup(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    photoGroupId: PhotoGroup['id'],
    data: Partial<FloorplanV2PhotoGroup>
  ): Promise<AxiosResponse<FloorplanV2PhotoGroup>> {
    return client.patch(
      `v2/floorplans/${planId}/photo-groups/${photoGroupId}`,
      data
    );
  },
  deletePhotoGroup(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    photoGroupId: PhotoGroup['id']
  ): Promise<AxiosResponse> {
    return client.delete(
      `v2/floorplans/${planId}/photo-groups/${photoGroupId}`
    );
  },
  updatePhoto(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    photoGroupId: PhotoGroup['id'],
    photoId: PhotoGroupPhoto['id'],
    update: Partial<PhotoGroupPhoto>
  ): Promise<AxiosResponse> {
    return client.put(
      `v2/floorplans/${planId}/photo-groups/${photoGroupId}/photos/${photoId}`,
      {
        name: update?.name,
      }
    );
  },
  bulk(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    updates: Array<
      | {
          type: 'plan.update.image';
          object_id: FloorplanV2Plan['id'];
          body: Partial<
            Pick<
              FloorplanV2Plan,
              | 'image_key'
              | 'image_width_pixels'
              | 'image_height_pixels'
              | 'origin_x_pixels'
              | 'origin_y_pixels'
              | 'origin_angle_degrees'
              | 'image_pixels_per_meter'
              | 'measurement_point_a_x_pixels'
              | 'measurement_point_a_y_pixels'
              | 'measurement_point_b_x_pixels'
              | 'measurement_point_b_y_pixels'
              | 'measurement_computed_length_meters'
              | 'measurement_user_entered_length_feet'
              | 'measurement_user_entered_length_inches'
            >
          >;
        }
      | {
          type: 'plan.update.scale';
          object_id: FloorplanV2Plan['id'];
          body: Pick<
            FloorplanV2Plan,
            | 'image_pixels_per_meter'
            | 'measurement_point_a_x_pixels'
            | 'measurement_point_a_y_pixels'
            | 'measurement_point_b_x_pixels'
            | 'measurement_point_b_y_pixels'
            | 'measurement_computed_length_meters'
            | 'measurement_user_entered_length_feet'
            | 'measurement_user_entered_length_inches'
          >;
        }
      | {
          type: 'plan.area_of_coverage.create';
          body: Unsaved<FloorplanV2AreaOfConcern>;
        }
      | {
          type: 'plan.area_of_coverage.delete';
          object_id: FloorplanV2AreaOfConcern['id'];
        }
      | {
          type: 'plan.area_of_coverage.update';
          object_id: FloorplanV2AreaOfConcern['id'];
          body: Partial<FloorplanV2AreaOfConcern>;
        }
      | { type: 'plan.sensor.create'; body: Unsaved<FloorplanV2Sensor> }
      | {
          type: 'plan.sensor.update';
          object_id: FloorplanV2Sensor['id'];
          body: Partial<FloorplanV2Sensor>;
        }
      | { type: 'plan.sensor.delete'; object_id: FloorplanV2Sensor['id'] }
      | {
          type: 'plan.wall_segment.create';
          body: Unsaved<FloorplanV2WallSegment>;
        }
      | {
          type: 'plan.wall_segment.update';
          object_id: FloorplanV2WallSegment['id'];
          body: Partial<FloorplanV2WallSegment>;
        }
      | {
          type: 'plan.wall_segment.delete';
          object_id: FloorplanV2WallSegment['id'];
        }
      | { type: 'plan.doorway.create'; body: Unsaved<FloorplanV2Threshold> }
      | {
          type: 'plan.doorway.update';
          object_id: FloorplanV2Threshold['id'];
          body: Partial<FloorplanV2Threshold>;
        }
      | { type: 'plan.doorway.delete'; object_id: FloorplanV2Threshold['id'] }
      | { type: 'plan.space.create'; body: Unsaved<FloorplanV2Space> }
      | {
          type: 'plan.space.update';
          object_id: FloorplanV2Space['id'];
          body: Partial<FloorplanV2Space>;
        }
      | { type: 'plan.space.delete'; object_id: FloorplanV2Space['id'] }
    >
  ): Promise<AxiosResponse> {
    return client.put<{
      ids: Array<string | null>;
      plans: { [planId: string]: FloorplanV2Plan };
      sensors: { [planSensorId: string]: FloorplanV2Sensor };
      areasOfConcern: { [areaOfConcernId: string]: FloorplanV2AreaOfConcern };
      spaces: { [spaceId: string]: FloorplanV2Space };
      doorways: { [thresholdId: string]: FloorplanV2Threshold };
    }>(`v2/floorplans/${planId}/bulk-update`, { updates });
  },

  listWallSegments(
    client: AxiosInstance,
    planId: FloorplanV2Plan['id'],
    page = 1,
    pageSize = 1000
  ) {
    return client.get<Paginated<FloorplanV2WallSegment>>(
      `v2/floorplans/${planId}/wall-segments?page=${page}&page_size=${pageSize}`
    );
  },
};
